// import handleWelcomeScroll from "./handleWelcomeScroll";
// import replaceLogotype from "./replaceLogotype";
// import googleMaps from "./googleMaps";
// import handleExecutionDetails from "./handleExecutionDetails";

import Cookies from 'js-cookie';

const init = () => {
    if (typeof google !== "undefined") {
        // googleMaps.init();
    }

    if (Cookies.get('cookie_bar')) {
        // console.log(Cookies.get('cookie_bar'));
        $("#cookie-bar").removeClass("show");
        $("#cookie-bar").addClass("hide");
    } else {
        $("#cookie-bar").addClass("show");
        $("#cookie-bar").removeClass("hide");

    }

    $("#cookie_button").click(function() {
        console.log(Cookies.get('cookie_bar'));
        Cookies.set('cookie_bar', 'clicked', { expires: 10 });
        console.log(Cookies.get('cookie_bar'));

        $("#cookie-bar").addClass("hide");

    });


    $(window).scroll(function() {
        if ($(this).scrollTop() > 0) {
            $('.midnightHeader.top .compania-logo').addClass("show");
        } else {
            $('.midnightHeader.top .compania-logo').removeClass("show");
        }
    });


    function msieversion() {

        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) // If Internet Explorer, return version number
        {
            $("#infoIE").show();
        } else // If another browser, return 0
        {
            $("#infoIE").hide();
        }


    }
    msieversion();
    setTimeout(() => {
        // handleWelcomeScroll.init();
    }, 1000);

    // replaceLogotype.init();
    // handleExecutionDetails.init();


    function scrollToPosition(position, offset) {
        window.scroll({
            top: position.top + offset,
            left: 0,
            behavior: 'smooth'
        });
    }

    $('.case-study-banner-link').click(function(e) {
        let url = $(this).attr('href');
        if (url.substr(0, 1) == '#') {
            let target = $(url);
            if (!target.length) {
                return;
            }
            e.preventDefault();

            scrollToPosition(target.offset(), 0);
        }
    });

    $('.case-study-link').click(function(e) {
        let url = $(this).attr('href');
        if (url.substr(0, 1) == '#') {
            let target = $(url);
            if (!target.length) {
                return;
            }
            e.preventDefault();

            scrollToPosition(target.offset(), 0);
        }
    });


    function animateWelcomeScreen() {

        let left = "calc(0% + 80px)";
        let top = "calc(0% + 45px)";
        let endWidth = "100px";
        // if (screen.width < 1500) {
        //     // left = "12%";
        //     top = "8%";

        // }
        // if (screen.width < 1200) {

        //     endWidth = "11%";
        //     left = "7%";
        //     top = "7%";
        // }
        // if (screen.width < 998) {
        //     endWidth = "12%";
        //     left = "9%";
        //     top = "6%";

        // }
        if (screen.width < 500) {
            endWidth = "75px";
            left = "calc(0% + 60px)";
            top = "calc(0% + 33.5px)";

        }

        // if (screen.width < 480) {
        //     endWidth = "20%";
        //     left = "14%";
        //     top = "4%";
        // }


        anime({
            targets: '#welcome-animation path',
            "stroke-dashoffset": [10736, 0],
            duration: 2100,
            delay: 0,
            easing: 'cubicBezier(0.7,0,0.2,1)'
        });

        var timeline = anime.timeline({});

        timeline.add({
            targets: '.welcome-wrapper .logo',
            width: [0, "20%"],
            duration: 2000,
            delay: 0,
            // easing: 'cubicBezier(1,0,0.2,1)',
            easing: 'easeInCubic'
                // }).add({
                //     targets: '.welcome-wrapper .logo',
                //     width: ["20%", "25%"],
                //     duration: 1000,
                //     delay: 0,
                //     easing: 'easeInCubic'

        }).add({
            targets: ['.welcome-wrapper .logo', '.welcome-wrapper svg'],
            opacity: [1, 0],
            duration: 1000,
            delay: 0,
            easing: 'linear'
                // }).add({
                //     targets: '.welcome-wrapper svg',
                //     opacity: [1, 0],
                //     duration: 1000,
                //     delay: 0,
                //     easing: 'easeInOutCubic',
                //     // offset: '-=1000'

        }).add({
            targets: '.welcome-wrapper .logo',
            width: ["25%", endWidth],
            duration: 50,
            delay: 0,
            easing: 'easeOutCubic'
        }).add({
            targets: '.welcome-wrapper .logo',
            left: ["50%", left],
            top: ["50%", top],
            duration: 1,
            delay: 0,
            easing: 'easeInOutCubic'
        }).add({
            targets: '.welcome-wrapper .logo',
            opacity: [0, 1],
            duration: 500,
            delay: 0,
            easing: 'easeInOutCubic'

        }).add({
            targets: '.welcome-wrapper .line1',
            opacity: [0, 1],
            duration: 1000,
            delay: anime.stagger(500),
            easing: 'easeInOutCubic'

        }).add({
            targets: '.welcome-wrapper .line2',
            opacity: [0, 1],
            duration: 1000,
            delay: 1600,
            easing: 'easeInOutCubic'
        }).add({
            targets: '.welcome-wrapper .line-title',
            opacity: [0, 1],
            duration: 1000,
            delay: 50,
            easing: 'easeInOutCubic'
        }).add({
            targets: '.welcome-wrapper .become_client_button',
            opacity: [0, 1],
            duration: 1000,
            delay: 50,
            easing: 'easeInOutCubic'
        }).add({
            targets: '.welcome-wrapper .progress-bar',
            opacity: [0, 1],
            duration: 1000,
            delay: 50,
            easing: 'easeInOutCubic'
        });


        // $("#welcome-animation path").css("transition", "none");
        // $("#welcome-animation path").removeClass("animate");
        //
        // setTimeout(function() {
        //   $("#welcome-animation path").css("transition", "all 4s cubic-bezier(0.65, 0.05, 0.36, 1)");
        //   $("#welcome-animation path").addClass("animate");
        // }, 1000);
    }

    setTimeout(function() {
        animateWelcomeScreen();
    }, 0);

    // $(".welcome").click(function() {
    //   animateWelcomeScreen()
    // });


    const progressBar = document.getElementsByClassName('progress-bar')[0];
    // window.addEventListener("beforeunload", function(e) {
    setTimeout(function() {

        setInterval(() => {
            const computedStyle = getComputedStyle(progressBar)
            const width = parseFloat(computedStyle.getPropertyValue('--width')) || 0
            progressBar.style.setProperty('--width', width + .1)
        }, 100)
    }, 8000);
    // }, false);
    window.addEventListener('load', (event) => {
        if (progressBar) {

            progressBar.classList.add("finish");
        }
    });

    $('.case-study-content-text').each(function(index) {
        var clientMeetingText = $(this).html();

        var boldClientMeetingText = clientMeetingText.replace("dystrybutorem Miodu Manuka Health New Zealand", "dystrybutorem <b>Miodu Manuka Health New Zealand</b>");

        $(this).html(boldClientMeetingText);
    });

    $('.case-study-content-text').each(function(index) {
        var clientMeetingText = $(this).html();

        var boldClientMeetingText = clientMeetingText.replace("Łyżeczka natury to konkurs dla konsumentów:", "<b>Łyżeczka natury to konkurs dla konsumentów:</b>");

        $(this).html(boldClientMeetingText);
    });
    $('.case-study-content-text').each(function(index) {
        var clientMeetingText = $(this).html();

        var boldClientMeetingText = clientMeetingText.replace("Konkurs „Łyżeczka informacji” ma na celu kreowanie wizerunku marki:", "<b>Konkurs „Łyżeczka informacji” ma na celu kreowanie wizerunku marki:</b>");

        $(this).html(boldClientMeetingText);
    });


    $('.executions-filters a').click(function() {
        $('.executions-filters li').removeClass('active');
        $(this).parent('li').addClass('active');

        var type = $(this).data('type');
        if (type === 'sector-all') {
            $('.executions .grid-container > div').not('.behance').removeClass('inactive');
        } else {
            $('.executions .grid-container > div').not('.behance').addClass('inactive');
            $('.' + type).removeClass('inactive');
        }
    });

};

document.addEventListener("DOMContentLoaded", init);
